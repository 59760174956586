import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
// import store from '../store'
import { getStorage } from '@/assets/js/storage'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    // eslint-disable-next-line no-unused-vars
  },
  {
    path: '/forgot-password/:string',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "home" */ '../views/ForgotPasswordView.vue'),
    // eslint-disable-next-line no-unused-vars
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Customer/CustomersView.vue')
  },
  {
    path: '/customer/create',
    name: 'customerCreate',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Customer/CustomerCreateView.vue')
  },
  {
    path: '/customer/:id',
    name: 'customerEdit',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Customer/CustomerEditView.vue')
  },
  // {
  //   path: '/prescriber',
  //   name: 'prescriber',
  //   component: () => import(/* webpackChunkName: "customer" */ '../views/Prescriber/PrescribersView.vue')
  // },
  // {
  //   path: '/prescriber/create',
  //   name: 'prescriberCreate',
  //   component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Prescriber/PrescriberCreateView.vue')
  // },
  // {
  //   path: '/prescriber/:id',
  //   name: 'prescriberEdit',
  //   component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Prescriber/PrescriberEditView.vue')
  // },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Product/ProductsView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Order/OrdersView.vue')
  },
  {
    path: '/order/create',
    name: 'orderCreate',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Order/OrderCreateView.vue')
  },
  {
    path: '/order/:id',
    name: 'orderView',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Order/OrderView.vue')
  },
  {
    path: '/order/edit/:id',
    name: 'orderEdit',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Order/OrderEditView.vue')
  },
  {
    path: '/order/success/:id',
    name: 'orderSuccess',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Order/OrderSucessView.vue')
  },
  {
    path: '/service',
    name: 'ordersService',
    component: () => import(/* webpackChunkName: "ordersService" */ '../views/Service/OrdersServiceView.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "customer" */ '../views/User/UsersView.vue')
  },
  {
    path: '/user/create',
    name: 'userCreate',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/User/UserCreateView.vue')
  },
  {
    path: '/user/:id',
    name: 'userEdit',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/User/UserEditView.vue')
  },
  {
    path: '/permission',
    name: 'permission',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Permission/PermissionsView.vue')
  },
  {
    path: '/permission/create',
    name: 'permissionCreate',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Permission/PermissionCreateView.vue')
  },
  {
    path: '/permission/:id',
    name: 'permissionEdit',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Permission/PermissionEditView.vue')
  },
  {
    path: '/role',
    name: 'roles',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Role/RolesView.vue')
  },
  {
    path: '/role/create',
    name: 'roleCreate',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Role/RoleCreateView.vue')
  },
  {
    path: '/role/:id',
    name: 'roleEdit',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Role/RoleEditView.vue')
  },
  {
    path: '/enterprise',
    name: 'enterprises',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Enterprise/EnterprisesView.vue')
  },
  {
    path: '/enterprise/create',
    name: 'enterpriseCreate',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Enterprise/EnterpriseCreateView.vue')
  },
  {
    path: '/enterprise/:id',
    name: 'enterpriseEdit',
    component: () => import(/* webpackChunkName: "customerEdit" */ '../views/Enterprise/EnterpriseEditView.vue')
  },
  {
    path: '/type-order',
    name: 'typeOrder',
    component: () => import(/* webpackChunkName: "typeOrder" */ '../views/TypeOrder/TypeOrdersView.vue')
  },
  {
    path: '/type-order/create',
    name: 'typeOrderCreate',
    component: () => import(/* webpackChunkName: "typeOrderCreate" */ '../views/TypeOrder/TypeOrderCreateView.vue')
  },
  {
    path: '/type-order/:id',
    name: 'typeOrderEdit',
    component: () => import(/* webpackChunkName: "typeOrderEdit" */ '../views/TypeOrder/TypeOrderEditView.vue')
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "payment" */ '../views/Payment/PaymentsView.vue')
  },
  {
    path: '/payment/create',
    name: 'paymentCreate',
    component: () => import(/* webpackChunkName: "paymentCreate" */ '../views/Payment/PaymentCreateView.vue')
  },
  {
    path: '/payment/:id',
    name: 'paymentEdit',
    component: () => import(/* webpackChunkName: "paymentEdit" */ '../views/Payment/PaymentEditView.vue')
  },
  {
    path: '/order/info/:id/:type',
    name: 'OrderInfo',
    component: () => import(/* webpackChunkName: "OrderInfo" */ '../views/Order/OrderInfoView.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/type-rental',
    name: 'typeRental',
    component: () => import(/* webpackChunkName: "typeRental" */ '../views/TypeRental/TypeRentalsView.vue')
  },
  {
    path: '/type-rental/create',
    name: 'typeRentalCreate',
    component: () => import(/* webpackChunkName: "typeRentalCreate" */ '../views/TypeRental/TypeRentalCreateView.vue')
  },
  {
    path: '/type-rental/:id',
    name: 'typeRentalEdit',
    component: () => import(/* webpackChunkName: "typeRentalEdit" */ '../views/TypeRental/TypeRentalEditView.vue')
  },
  {
    path: '/type-adress',
    name: 'typeAdress',
    component: () => import(/* webpackChunkName: "typeAdress" */ '../views/TypeAdress/TypeAdressView.vue')
  },
  {
    path: '/type-adress/create',
    name: 'typeAdressCreate',
    component: () => import(/* webpackChunkName: "typeAdressCreate" */ '../views/TypeAdress/TypeAdressCreateView.vue')
  },
  {
    path: '/type-adress/:id',
    name: 'typeAdressEdit',
    component: () => import(/* webpackChunkName: "typeAdressEdit" */ '../views/TypeAdress/TypeAdressEditView.vue')
  },
  {
    path: '/type-storage',
    name: 'typeStorage',
    component: () => import(/* webpackChunkName: "typeStorage" */ '../views/TypeStorage/TypeStoragesView.vue')
  },
  {
    path: '/type-storage/create',
    name: 'typeStorageCreate',
    component: () => import(/* webpackChunkName: "typeStorageCreate" */ '../views/TypeStorage/TypeStorageCreateView.vue')
  },
  {
    path: '/type-storage/:id',
    name: 'typeStorageEdit',
    component: () => import(/* webpackChunkName: "typeStorageEdit" */ '../views/TypeStorage/TypeStorageEditView.vue')
  },
  {
    path: '/type-products-logistic',
    name: 'typeProductLogistic',
    component: () => import(/* webpackChunkName: "typeStorage" */ '../views/TypeProductLogistic/TypeProductsLogisticView.vue')
  },
  {
    path: '/type-products-logistic/logs',
    name: 'typeProductLogisticLogs',
    component: () => import(/* webpackChunkName: "typeProductLogisticLogs" */ '../views/TypeProductLogistic/TypeProductLogisticLogsView.vue')
  },
  {
    path: '/deadline',
    name: 'deadline',
    component: () => import(/* webpackChunkName: "deadline" */ '../views/Deadline/DeadlinesView.vue')
  },
  {
    path: '/deadline/create',
    name: 'deadlineCreate',
    component: () => import(/* webpackChunkName: "deadlineCreate" */ '../views/Deadline/DeadlineCreateView.vue')
  },
  {
    path: '/deadline/:id',
    name: 'deadlineEdit',
    component: () => import(/* webpackChunkName: "typeRentalEdit" */ '../views/Deadline/DeadlineEditView.vue')
  },
  {
    path: '/order/ticket/:id',
    name: 'OrderTicket',
    component: () => import(/* webpackChunkName: "OrderTicket" */ '../views/Order/OrderBilletView.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/report/orders',
    name: 'reportOrders',
    component: () => import(/* webpackChunkName: "reportOrders" */ '../views/Report/ReportOrdersView.vue')
  },
  {
    path: '/report/products',
    name: 'reportProducts',
    component: () => import(/* webpackChunkName: "reportProducts" */ '../views/Report/ReportProductsView.vue')
  },
  {
    path: '/report/rentals',
    name: 'reportRentals',
    component: () => import(/* webpackChunkName: "reportRentals" */ '../views/Report/ReportRentalsView.vue')
  },
  {
    path: '/report/scrums',
    name: 'reportScrums',
    component: () => import(/* webpackChunkName: "reportScrums" */ '../views/Report/ReportScrumsView.vue')
  },
  {
    path: '/report/rental/prescriber',
    name: 'reportRentalPrescriber',
    component: () => import(/* webpackChunkName: "reportScrums" */ '../views/Report/ReportRentalPrescriberView.vue')
  },
  {
    path: '/report/logistic/performance',
    name: 'reportLogisticPerformance',
    component: () => import(/* webpackChunkName: "reportLogisticPerformance" */ '../views/Report/ReportLogisticPerformanceView.vue')
  },
  {
    path: '/goal',
    name: 'goal',
    component: () => import(/* webpackChunkName: "goal" */ '../views/Goal/GoalsView.vue')
  },
  {
    path: '/rental',
    name: 'rental',
    component: () => import(/* webpackChunkName: "rental" */ '../views/Rental/RentalsView.vue')
  },
  {
    path: '/rental/create',
    name: 'rentalCreate',
    component: () => import(/* webpackChunkName: "rentalCreate" */ '../views/Rental/RentalCreateView.vue')
  },
  {
    path: '/rental/:id',
    name: 'rentalView',
    component: () => import(/* webpackChunkName: "rentalView" */ '../views/Rental/RentalView.vue')
  },
  {
    path: '/rental/edit/:id',
    name: 'rentalEdit',
    component: () => import(/* webpackChunkName: "rentalEdit" */ '../views/Rental/RentalEditView.vue')
  },
  {
    path: '/rental/payment/:id',
    name: 'rentalPayment',
    component: () => import(/* webpackChunkName: "rentalPayment" */ '../views/Rental/RentalPaymentView.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/rental/control-hours',
    name: 'rentalControlHoursView',
    component: () => import(/* webpackChunkName: "rentalControlHoursView" */ '../views/RentalControlHours/RentalControlHoursView.vue')
  },
  {
    path: '/rental/control-hours/create',
    name: 'rentalControlHoursCreate',
    component: () => import(/* webpackChunkName: "rentalControlHoursCreate" */ '../views/RentalControlHours/RentalControlHoursCreateView.vue')
  },
  {
    path: '/rental/control-hours/logs',
    name: 'rentalControlHoursLogs',
    component: () => import(/* webpackChunkName: "rentalControlHoursLogs" */ '../views/RentalControlHours/RentalControlHoursLogsView.vue')
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: () => import(/* webpackChunkName: "equipment" */ '../views/Equipment/EquipmentsView.vue')
  },
  {
    path: '/equipment/:id',
    name: 'equipmentView',
    component: () => import(/* webpackChunkName: "equipmentView" */ '../views/Equipment/EquipmentView.vue')
  },
  {
    path: '/rental-stock',
    name: 'rentalStock',
    component: () => import(/* webpackChunkName: "rental" */ '../views/RentalStock/RentalStocksView.vue')
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/Inventory/InventorysView.vue')
  },
  {
    path: '/inventory/create',
    name: 'inventoryCreate',
    component: () => import(/* webpackChunkName: "inventoryCreate" */ '../views/Inventory/InventoryCreateView.vue')
  },
  {
    path: '/inventory/edit/:id',
    name: 'inventoryEdit',
    component: () => import(/* webpackChunkName: "inventoryEdit" */ '../views/Inventory/InventoryEditView.vue')
  },
  {
    path: '/inventory/:id',
    name: 'inventoryShow',
    component: () => import(/* webpackChunkName: "inventoryShow" */ '../views/Inventory/InventoryShowView.vue')
  },
  {
    path: '/inventory/result/:id',
    name: 'inventoryResult',
    component: () => import(/* webpackChunkName: "inventoryResult" */ '../views/Inventory/InventoryResultView.vue')
  },
  {
    path: '/conference',
    name: 'conference',
    component: () => import(/* webpackChunkName: "conference" */ '../views/Conference/ConferencesView.vue')
  },
  {
    path: '/conference/create',
    name: 'conferenceCreate',
    component: () => import(/* webpackChunkName: "conferenceCreate" */ '../views/Conference/ConferenceCreateView.vue')
  },
  {
    path: '/conference/:id',
    name: 'conferenceShow',
    component: () => import(/* webpackChunkName: "conferenceShow" */ '../views/Conference/ConferenceShowView.vue')
  },
  {
    path: '/conference/result/:id',
    name: 'conferenceResult',
    component: () => import(/* webpackChunkName: "conferenceResult" */ '../views/Conference/ConferenceResultView.vue')
  },
  {
    path: '/conference/logistic',
    name: 'conferenceLogistic',
    component: () => import(/* webpackChunkName: "conferenceLogistic" */ '../views/ConferenceLogistic/ConferenceLogisticView.vue')
  },
  {
    path: '/conference/logistic/create',
    name: 'conferenceLogisticCreate',
    component: () => import(/* webpackChunkName: "conferenceLogisticCreate" */ '../views/ConferenceLogistic/ConferenceLogisticCreateView.vue')
  },
  {
    path: '/conference/logistic/:id',
    name: 'conferenceLogisticShow',
    component: () => import(/* webpackChunkName: "conferenceLogisticShow" */ '../views/ConferenceLogistic/ConferenceLogisticShowView.vue')
  },
  {
    path: '/inventory/logistic',
    name: 'inventoryLogistic',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/InventoryLogistic/InventorysView.vue')
  },
  {
    path: '/inventory/logistic/edit/:id',
    name: 'inventoryLogisticEdit',
    component: () => import(/* webpackChunkName: "inventoryLogisticEdit" */ '../views/InventoryLogistic/InventoryEditView.vue')
  },
  {
    path: '/inventory/logistic/:id',
    name: 'inventoryLogisticShow',
    component: () => import(/* webpackChunkName: "inventoryLogisticShow" */ '../views/InventoryLogistic/InventoryShowView.vue')
  },
  {
    path: '/inventory/logistic/:id/items',
    name: 'inventoryLogisticItemsShow',
    component: () => import(/* webpackChunkName: "inventoryLogisticItemsShow" */ '../views/InventoryLogistic/InventoryProductShowView.vue')
  },
  {
    path: '/inventory/logistic/:id/result',
    name: 'inventoryLogisticResultShow',
    component: () => import(/* webpackChunkName: "inventoryLogisticResultShow" */ '../views/InventoryLogistic/InventoryProductResultView.vue')
  },
  {
    path: '/inventory/single-count',
    name: 'singleCountsView',
    component: () => import(/* webpackChunkName: "singleCountsView" */ '../views/SingleCount/SingleCountsView.vue')
  },
  {
    path: '/inventory/single-count/create',
    name: 'singleCountCreate',
    component: () => import(/* webpackChunkName: "singleCountCreate" */ '../views/SingleCount/SingleCountCreateView.vue')
  },
  {
    path: '/inventory/single-count/:id',
    name: 'singleCountShow',
    component: () => import(/* webpackChunkName: "singleCountShow" */ '../views/SingleCount/SingleCountShowView.vue')
  },
  {
    path: '/stockroom',
    name: 'stockrooms',
    component: () => import(/* webpackChunkName: "stockrooms" */ '../views/StockRoom/StockRoomViews.vue')
  },
  {
    path: '/stockroom/create',
    name: 'stockroomCreate',
    component: () => import(/* webpackChunkName: "stockroomCreate" */ '../views/StockRoom/StockRoomCreateView.vue')
  },
  {
    path: '/stockroom/:id',
    name: 'stockroomView',
    component: () => import(/* webpackChunkName: "stockroomView" */ '../views/StockRoom/StockRoomView.vue')
  },
  {
    path: '/stockroom/edit/:id',
    name: 'stockroomEdit',
    component: () => import(/* webpackChunkName: "stockroomEditView" */ '../views/StockRoom/StockRoomEditView.vue')
  },
  {
    path: '/stockroom/products',
    name: 'stockroomProducts',
    component: () => import(/* webpackChunkName: "stockroomProducts" */ '../views/StockRoom/StockRoomProductsView.vue')
  },
  {
    path: '/type-service-order',
    name: 'typeServiceOrder',
    component: () => import(/* webpackChunkName: "typeServiceOrder" */ '../views/TypeServiceOrder/TypeServiceOrdersView.vue')
  },
  {
    path: '/type-service-order/create',
    name: 'typeServiceOrderCreate',
    component: () => import(/* webpackChunkName: "typeServiceOrderCreate" */ '../views/TypeServiceOrder/TypeServiceOrderCreateView.vue')
  },
  {
    path: '/type-service-order/:id',
    name: 'typeServiceOrderEdit',
    component: () => import(/* webpackChunkName: "typeServiceOrderEdit" */ '../views/TypeServiceOrder/TypeServiceOrderEditView.vue')
  },
  {
    path: '/service-order',
    name: 'serviceOrder',
    component: () => import(/* webpackChunkName: "serviceOrder" */ '../views/ServiceOrder/ServiceOrdersView.vue')
  },
  {
    path: '/service-order/create',
    name: 'serviceOrderCreate',
    component: () => import(/* webpackChunkName: "serviceOrderCreate" */ '../views/ServiceOrder/ServiceOrderCreateView.vue')
  },
  {
    path: '/service-order/:id',
    name: 'serviceOrderView',
    component: () => import(/* webpackChunkName: "serviceOrderView" */ '../views/ServiceOrder/ServiceOrderView.vue')
  },
  {
    path: '/service-order/:id/equipment',
    name: 'ServiceOrderEquipment',
    component: () => import(/* webpackChunkName: "ServiceOrderEquipment" */ '../views/ServiceOrder/ServiceOrderEquipmentView.vue')
  },
  {
    path: '/service-order-equipment',
    name: 'ServiceOrderEquipments',
    component: () => import(/* webpackChunkName: "ServiceOrderEquipments" */ '../views/ServiceOrder/ServiceOrderEquipmentsView.vue')
  },
  {
    path: '/service-order/:id/budget',
    name: 'ServiceOrderBudget',
    component: () => import(/* webpackChunkName: "ServiceOrderBudget" */ '../views/ServiceOrder/ServiceOrderBudgetView.vue')
  },
  {
    path: '/service-order/GeneratedOrderView',
    name: 'GeneratedOrderView.vue',
    component: () => import(/* webpackChunkName: "GeneratedOrderView.vue" */ '../views/ServiceOrder/GeneratedOrderView.vue')
  },
  {
    path: '/service-order/SalesReceiptView',
    name: 'SalesReceiptView.vue',
    component: () => import(/* webpackChunkName: "SalesReceiptView.vue" */ '../views/ServiceOrder/SalesReceiptView.vue')
  },
  {
    path: '/service-order/:id/:type',
    name: 'serviceOrderRequest',
    component: () => import(/* webpackChunkName: "serviceOrderRequest" */ '../views/ServiceOrder/FinancialNoteView.vue')
  },
  {
    path: '/service-order/PaymentServiceOrderView',
    name: 'PaymentServiceOrderView.vue',
    component: () => import(/* webpackChunkName: "PaymentServiceOrderView.vue" */ '../views/ServiceOrder/PaymentServiceOrderView.vue')
  },
  {
    path: '/service-order/ServiceOrderSendPaymentView',
    name: 'ServiceOrderSendPaymentView.vue',
    component: () => import(/* webpackChunkName: "ServiceOrderSendPaymentView.vue" */ '../views/ServiceOrder/ServiceOrderSendPaymentView.vue')
  },
  {
    path: '/service-order/services',
    name: 'serviceOrderServices',
    component: () => import(/* webpackChunkName: "serviceOrderServices" */ '../views/Service/ServiceOrderServicesView.vue')
  },
  {
    path: '/service-order/requests',
    name: 'serviceOrderRequests',
    component: () => import(/* webpackChunkName: "serviceOrderOrders" */ '../views/ServiceOrder/ServiceOrderRequestsView.vue')
  },
  {
    path: '/employee/:id',
    name: 'employee',
    component: () => import(/* webpackChunkName: "employe" */ '../views/Employees/EmployeesCreateView.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/scrum',
    name: 'scrums',
    component: () => import(/* webpackChunkName: "ScrumsView.vue" */ '../views/Scrum/ScrumsView.vue')
  },
  {
    path: '/scrum/create',
    name: 'scrumCreate',
    component: () => import(/* webpackChunkName: "ScrumsView.vue" */ '../views/Scrum/ScrumCreateView.vue')
  },
  {
    path: '/scrum/:id/sprint',
    name: 'scrumSprint',
    component: () => import(/* webpackChunkName: "SprintView.vue" */ '../views/Scrum/SprintView.vue')
  },
  {
    path: '/scrum/:scrum/sprint/:sprint/tasks',
    name: 'scrumSprintTasks',
    component: () => import(/* webpackChunkName: "SprintView.vue" */ '../views/Scrum/TasksViews.vue')
  },
  {
    path: '/fte',
    name: 'fte',
    component: () => import(/* webpackChunkName: "fte" */ '../views/Fte/FtesView.vue')
  },
  {
    path: '/fte/create',
    name: 'fteCreate',
    component: () => import(/* webpackChunkName: "fteCreate" */ '../views/Fte/FteCreateView.vue')
  },
  {
    path: '/fte/:id',
    name: 'fteEdit',
    component: () => import(/* webpackChunkName: "fteEdit" */ '../views/Fte/FteEditView.vue')
  },
  {
    path: '/cashier',
    name: 'cashier',
    component: () => import(/* webpackChunkName: "cashier" */ '../views/Cashier/CashierViews.vue')
  },
  {
    path: '/cashier/:id',
    name: 'cashierView',
    component: () => import(/* webpackChunkName: "cashierLogs" */ '../views/Cashier/CashierView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   const isPublic = to?.matched?.some(record => record?.meta?.public);
//   const onlyWhenLoggedOut = to?.matched.some(
//       record => record?.meta?.onlyWhenLoggedOut
//   );
//   const loggedIn = (getStorage('loggedIn') === 'true')
//   console.log("FROM: ")
//   console.log(from)
//   console.log("TO", to)
//   const redirect = from?.fullPath.indexOf('forgot-password') !== -1 ? '' : from?.fullPath;
//   console.log("REDIRECT: " +redirect)
//   if (!isPublic && !loggedIn) {
//     const router = {path: '/'}
//     if (redirect != '/') {
//       const router = {path: '/', query: {redirect: redirect}}
//     }
//     // return next(router);
//     return next({
//       path: "/",
//       query: { redirect: redirect }
//     });
//   }

//   if (loggedIn && onlyWhenLoggedOut) {
//     return next("/home");
//   }
  
//   next();
// });

router.beforeEach((to, from) => {
  // document.title = "Sistema CPAPS"
  const isPublic = to?.matched?.some(record => record?.meta?.public);
  const onlyWhenLoggedOut = to?.matched.some(
      record => record?.meta?.onlyWhenLoggedOut
  );
  const loggedIn = (getStorage('loggedIn') === 'true')
  let redirect = from?.fullPath.indexOf('forgot-password') !== -1 ? '' : from?.fullPath;
  if (!redirect || redirect.indexOf('?redirect=')) {
    redirect = String(from?.query.redirect) ?? ''
  }

  if (!isPublic && !loggedIn) {
    return {
      path: "/",
      query: { redirect: redirect }
    };
  }
  if (loggedIn && to.fullPath == '/') {
    return {
      path: "/home"
    };
  }

  if ((redirect && redirect != 'undefined') && loggedIn) {
    //eslint-disable-next-line
    // return next();
    delete from?.query.redirect;
    return {path: redirect}
  }
  // next();
});

export default router
